import React, { useMemo, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import StrapiImage from '../../types/StrapiImage'
import PageWidth from '../PageWidth'

interface ReviewsProps {
  title: string
  subtitle: string
  reviews: {
    data: {
      attributes: {
        user: string
        body: string
        logo: StrapiImage
      }
      id: number
    }[]
  }
}

const Reviews = ({ title, subtitle, reviews }: ReviewsProps) => {
  const [activeIndex, setActiveIndex] = useState(0)
  // Double the data so we can loop through it, even if it is short
  const data = useMemo(() => [...reviews.data, ...reviews.data], [reviews.data])

  return (
    <div className="blockReviews">
      <PageWidth className="blockReviews__container">
        <p className="blockReviews__subtitle">{subtitle}</p>
        <h3 className="blockReviews__title">{title}</h3>
        <div style={{ position: 'relative', height: 300 }}>
          {data.map((review, index) => (
            <div
              className={`blockReviews__review
            ${activeIndex === index && 'activeCard'} 
        ${(activeIndex + 1) % data.length === index && 'nextCard'}
        ${(activeIndex + 2) % data.length === index && 'secondNextCard'}
        ${(activeIndex + data.length - 1) % data.length === index && 'prevCard'}
        ${
          (activeIndex + data.length - 2) % data.length === index &&
          'secondPrevCard'
        }
            `}
              key={review.id}
            >
              <div className={'blockReviews__card'}>
                <p>{review.attributes.body}</p>
              </div>
              <p className="blockReviews__cardTitle">
                {review.attributes.user}
              </p>
            </div>
          ))}
          <FontAwesomeIcon
            icon="chevron-left"
            className="blockReviews__chevronLeft"
            onClick={() =>
              setActiveIndex((a) => {
                if (a - 1 < 0) {
                  return data.length - 1
                }
                return a - 1
              })
            }
          ></FontAwesomeIcon>
          <FontAwesomeIcon
            icon="chevron-right"
            className="blockReviews__chevronRight"
            onClick={() =>
              setActiveIndex((a) => {
                if (a + 1 > data.length - 1) {
                  return 0
                }
                return a + 1
              })
            }
          ></FontAwesomeIcon>
        </div>
      </PageWidth>
    </div>
  )
}

export default Reviews
